<template>
	<div class="flex-1 bg-white pa-20 radius-20">

		<div class="under-line-dick pb-10 flex-row">
			<h3 class="flex-1">카르텔 탈퇴</h3>
		</div>

		<div class="mt-30 text-center">

			<div class="bg-icon01 mt-50">
				<h4>카르텔 탈퇴 전 꼭 확인해주세요</h4>
			</div>

			<p class="mt-30">
				해당 카르텔 탈퇴 시 7일 이후 재가입이 가능합니다. <br/> 작성한 글과 댓글은 자동으로 삭제되지 않습니다.
			</p>

			<div class="mt-30">
				<input
					v-model="is_out"
					type="checkbox" id="resign"
				>
				<label for="resign"> 카르텔 탈퇴에 동의합니다.</label>
			</div>

			<div class="mt-30">
				<button
					@click="onPin('check')"
					class="btn-inline btn-primary pa-20 radius-20"
					:disabled="!is_out"
				>탈퇴하기</button>
			</div>
		</div>
	</div>
</template>

<script>

	export default {
		name: 'mafia083'
		, components: {}
		, props: ['user']
		, data: function(){
			return {
				program: {
					name: '카르텔 탈퇴'
					, title: '카르텔 탈퇴'
					, type: 'cartel_sub'
					, not_header: true
					, not_footer: true
					, bg_contents: 'bg-gray01'
					, is_side: true
					, cartel: ''
				}
				, item_cartel: {

				}
				,is_pin: false
				,pin_option: {
					pin_type: 'check'
				}
				,is_out: false
			}
		}
		, methods: {

			getData: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_info
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
						}
						, type: true
					})

					if(result.success){
						this.item_cartel = result.data
						this.program.cartel = result.data

						switch (this.item_cartel.cartl_concern_sphere_code){
							case 'CM00400001': this.$set(this.item_cartel, 'type', 'music'); break
							case 'CM00400002': this.$set(this.item_cartel, 'type', 'art') ; break
							case 'CM00400003': this.$set(this.item_cartel, 'type', 'fashion'); break
							case 'CM00400004': this.$set(this.item_cartel, 'type', 'influence'); break
						}
					}else{
						throw result.message
					}
				}catch (e) {
					this.$log.console(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			,postCartelOut: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_cartel_out
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
						}
						, type: true
					})

					if(result.success){
						this.$emit('to', { name: 'mafia044'})
					}else{
						throw result.message
					}
				}catch (e) {
					this.$log.console(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, onPin: function (){
				this.$bus.$emit('onPin', 'check')
			}
		}
		, created() {
			this.$bus.$emit('onLoad', this.program)
			this.getData()

			this.$bus.$on('pinCallback', ()=> {
				this.postCartelOut()
				this.$bus.$off('pinCallback')
			})
		}
	}
</script>